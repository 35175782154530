import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"
import ImageGolf from "../images/golf-page.jpg";

function GolfPerformance() {
    return (
        <div>
            <Helmet>
    <title>Golf Performance & Injury Prevention | Kinetic Impact</title>
    <meta property="og:title" content="Golf Performance & Injury Prevention | Kinetic Impact" />
    <meta property="og:image" content={OpenGraphLogo} />
    <meta property="og:description" content="Enhance your golf game with specialized mobility training, swing mechanics optimization, and injury prevention. Expert programs for hip rotation, core stability, and power development." />
    <meta name="description" content="Improve your golf performance with professional movement training, injury prevention, and power development. Our experts help optimize your swing mechanics and enhance mobility for better play." />
    <meta name="keywords" content="golf performance, golf mobility training, hip rotation, swing mechanics, core stability, golf injury prevention, power development, golf warm-up, golfer's elbow" />
</Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} 
                  header={"Enhance Your Golf Game with Expert Movement Training"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Are you frustrated with inconsistent drives, nagging injuries, or a plateau in your golf game? Our specialized golf performance program helps you develop the mobility, stability, and power needed to improve your swing and prevent injuries.
                    </p>
                    
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Whether you're a weekend warrior or competitive player, our expert team understands the unique demands of golf and will help you optimize your body for peak performance on the course.
                    </p>

                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Let's work together to enhance your game and keep you playing pain-free for years to come.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Let's Work Together!
                        </button>
                    </a>
                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={ImageGolf} alt="Movement screening analysis"/>
                    </div>
                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>How We Help Golfers Excel</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Golf-Specific Movement Assessment</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our comprehensive evaluation identifies mobility restrictions, stability issues, and movement patterns that may be limiting your swing or increasing injury risk. We analyze your hip rotation, thoracic mobility, and core stability to create a targeted improvement plan.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Build Golf Fitness</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your golf game through enhanced body awareness and movement control. We focus on developing the key physical attributes that support a powerful, consistent swing, including hip mobility, rotational power, and core stability.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Prevention & Management</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Address and prevent common golf injuries like golfer's elbow, back pain, and hip issues. Our programs incorporate targeted exercises and techniques to build resilience and maintain long-term joint health.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Power Development</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Increase your driving distance through specialized strength and power training. We focus on developing rotational power, ground force production, and sequencing to help you generate more club head speed.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Start Your Golf Performance Journey
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Our Golf Performance Approach</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Custom Warm-Up Routines</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn effective pre-round warm-up exercises that prepare your body for optimal performance. Our routines focus on activating key muscle groups and enhancing mobility for better first-tee performance.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Mobility Enhancement</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your range of motion in critical areas like hips, thoracic spine, and shoulders. Our targeted mobility work helps you achieve a fuller, more efficient swing without compensation.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Core Stability Training</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Develop the core strength and stability needed for a powerful, controlled swing. Our exercises focus on rotational stability and anti-rotation control to improve swing consistency and protect your back.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Recovery Strategies</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn effective recovery techniques to maintain performance during long rounds and prevent fatigue-related injuries. We'll help you develop strategies for both during and after play.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Elevate Your Golf Game Today
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default GolfPerformance